import { Box, Paper, Grid, Typography } from '@mui/material';

import useColors from '../hooks/useColors';

const DetailModal = ({ item, close }) => {
  const colors = useColors();

  if (!item) return null;

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="rgba(0, 0, 0, 0.3)"
      zIndex={99}
      onClick={close}
    >
      <Box mx="20px" width={800} maxWidth="100vw" onClick={(e) => e.stopPropagation()}>
        <Paper elevation={3} style={{ backgroundColor: colors.detailModalBg }}>
          <Box padding="20px">
            <Grid container spacing="20px">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper elevation={2} style={{ backgroundColor: colors.cardBg }}>
                  <Box p={2}>
                    <Typography fontWeight={600} fontSize="1.25rem" color={colors.detailRanking}>
                      #{item.ranking}
                    </Typography>
                    <Box my={1} borderRadius={1} overflow="hidden">
                      <img src={item.image} alt={item.name} width="100%" />
                    </Box>
                    <Typography align="center" fontWeight={600} color={colors.id}>
                      {item.name.replace('#', '')}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography align="center" fontWeight={600} color="white">
                  Rarity Score
                </Typography>
                <Typography align="center" fontWeight={600} fontSize="20px" color={colors.rarityScore}>
                  {item.ratityScore}
                </Typography>
                <Box>
                  {item.attributes.map((attribute) => (
                    <Box key={attribute.trait_type} mt={1}>
                      <Typography color={colors.attribute} fontSize="14px" fontWeight={600}>
                        {attribute.trait_type}
                      </Typography>
                      <Box
                        py="5px"
                        px="10px"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius={1}
                        bgcolor={colors.attributeBar}
                      >
                        <Typography color={colors.attributeText} fontSize="14px" fontWeight={600}>
                          {attribute.value}
                        </Typography>
                        <Box
                          py="5px"
                          px="10px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={1}
                          bgcolor={colors.bg}
                        >
                          <Typography color="white" fontSize="14px" fontWeight={600}>
                            {attribute.percent.toFixed(3)}%
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default DetailModal;
