import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import Header from '../components/Header';
import Home from '../pages/Home/Home';
import useColors from '../hooks/useColors';

const Navigation = () => {
  const theme = useTheme();
  const isOneColumnLayout = useMediaQuery(theme.breakpoints.down('lg'));

  const props = !isOneColumnLayout ? { height: '100vh', overflow: 'hidden' } : {};
  const colors = useColors();

  return (
    <Box bgcolor={colors.bg} {...props}>
      <Header />
      <Routes>
        <Route path="/mirl" element={<Home />} />
        <Route path="/mirlxbybit" element={<Home />} />
        <Route path="*" element={<Navigate to="/mirl" replace />} />
      </Routes>
    </Box>
  );
};

export default Navigation;
