import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import axios from 'axios';
import qs from 'qs';

import nftItems from '../assets/jsons/nft-items.json';
import byBitNftItems from '../assets/jsons/bybit-nft-items.json';

const openseaApiKey = process.env.REACT_APP_OPEN_SEA_X_API_KEY;

const headerConfigs = openseaApiKey ? { headers: { 'X-API-KEY': openseaApiKey } } : {};

const api = axios.create({
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  ...headerConfigs,
});

const implementOwner = async (data) => {
  const token_ids = data.map((item) => item.edition);

  try {
    const res = await api.get(
      `https://api.opensea.io/api/v1/assets?asset_contract_address=0xfda07191be8d7019789cd1e1cf86d97604ce7351`,
      {
        params: { limit: 30, token_ids },
      }
    );
    const { assets } = res.data;
    const dataWithOwner = data.map((item) => {
      const asset = assets.find((nft) => nft.name === item.name);
      if (!asset) return item;

      return {
        ...item,
        owner: { name: asset.owner?.user?.username, address: asset.owner?.address },
      };
    });

    return dataWithOwner;
  } catch (err) {
    console.error(err);
  }

  return data;
};

const initFilters = {
  page: 1,
  limit: 28,
  attributes: [],
  search: '',
  startRanking: null,
  endRanking: null,
};

const useQuery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ items: [], totalItems: 0 });
  const [filters, setFilters] = useState({
    ...initFilters,
  });
  const [openingItem, setOpeningItem] = useState(null);

  const { pathname } = useLocation();
  const isMirlOfficial = useMemo(() => pathname === '/mirl', [pathname]);
  const json = useMemo(() => (isMirlOfficial ? nftItems : byBitNftItems), [isMirlOfficial]);

  const timeout = useRef(null);

  useEffect(() => {
    changeFilters({ ...initFilters });
  }, [json]);

  const getData = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const { page, limit, attributes, search, startRanking, endRanking } = filters;
      const jsonData = JSON.parse(JSON.stringify(json));

      let filteredData = jsonData;

      // filter by rankings
      if (startRanking && endRanking && startRanking <= endRanking) {
        filteredData = filteredData.filter((item, index) => startRanking <= index + 1 && index + 1 <= endRanking);
      }

      // filter by search
      if (search) {
        filteredData = filteredData.filter((item) => item.edition === search);
      }

      // filter by attributes
      if (attributes && attributes.length) {
        const traitGroups = groupBy(attributes, 'trait_type');
        filteredData = filteredData.filter((item) =>
          Object.keys(traitGroups).every((key) => {
            const attributeValue = item.attributes.find(({ trait_type }) => trait_type === key)?.value || null;
            const filteredAttributeValues = traitGroups[key].map(({ value }) => value);
            return filteredAttributeValues.includes(attributeValue);
          })
        );
      }

      const pagedData = filteredData.slice(limit * (page - 1), limit * page);
      const pagedDataWithOwner = isMirlOfficial ? await implementOwner(pagedData) : pagedData;
      setData({ items: pagedDataWithOwner, totalItems: filteredData.length });
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }, [filters, json, isMirlOfficial]);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(getData, 200);
  }, [getData]);

  const changeFilters = useCallback((values) => setFilters({ ...filters, ...values }), [filters]);

  const toggleAttributeFilters = useCallback(
    (attribute) => {
      if (
        filters.attributes.some(
          ({ trait_type, value }) => trait_type === attribute.trait_type && value === attribute.value
        )
      ) {
        changeFilters({
          attributes: filters.attributes.filter(
            ({ trait_type, value }) => !(trait_type === attribute.trait_type && value === attribute.value)
          ),
          page: 1,
        });
        return;
      }

      changeFilters({
        attributes: [...filters.attributes, { trait_type: attribute.trait_type, value: attribute.value }],
        page: 1,
      });
    },
    [changeFilters, filters.attributes]
  );

  const selectAllAttributeFilter = useCallback(
    ({ name, values }) => {
      const currentAttributes = JSON.parse(JSON.stringify(filters.attributes));

      for (const value of values) {
        if (!currentAttributes.some((attribute) => attribute.trait_type === name && attribute.value === value)) {
          currentAttributes.push({ trait_type: name, value });
        }
      }

      changeFilters({ attributes: currentAttributes, page: 1 });
    },
    [changeFilters, filters.attributes]
  );

  const deSelectAllAttributeFilter = useCallback(
    (trait_type) => {
      changeFilters({ attributes: filters.attributes.filter((item) => item.trait_type !== trait_type), page: 1 });
    },
    [changeFilters, filters.attributes]
  );

  const isAllAttributeSelected = useCallback(
    ({ name, values }) => {
      return values.every((value) =>
        filters.attributes.find((attribute) => attribute.trait_type === name && attribute.value === value)
      );
    },
    [filters.attributes]
  );

  const { items, totalItems } = data;
  const totalPages = useMemo(() => Math.ceil(totalItems / filters.limit), [totalItems, filters.limit]);

  return {
    isLoading,
    isMirlOfficial,
    items,
    totalItems,
    totalPages,
    filters,
    openingItem,
    isAllAttributeSelected,
    selectAllAttributeFilter,
    deSelectAllAttributeFilter,
    setOpeningItem,
    changeFilters,
    toggleAttributeFilters,
  };
};

export default useQuery;
