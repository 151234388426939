import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

import InputPagination from './InputPagination';
import useColors from '../../../hooks/useColors';

const ListFooter = ({ totalPages, filters, changeFilters }) => {
  const colors = useColors();
  const numberOfPageButtons = Math.max(Math.min(3, Math.floor(totalPages / 2)), 1);
  const isShowDots = numberOfPageButtons * 2 < totalPages;

  const startButtons = Array(numberOfPageButtons)
    .fill('')
    .map((_, index) => index + 1);
  const endButtons =
    numberOfPageButtons > 1
      ? Array(numberOfPageButtons)
          .fill('')
          .map((_, index) => totalPages - index)
          .reverse()
      : [];

  const theme = useTheme();
  const isOneColumnLayout = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {!isOneColumnLayout && (
        <Box display="flex" gap="10px" alignItems="center" flexGrow={1}>
          <Typography fontSize="0.9rem" fontWeight={600} color={colors.footer}>
            Pages
          </Typography>
          {startButtons.map((item) => (
            <Box
              key={item}
              px="10px"
              py="5px"
              borderRadius={0.5}
              bgcolor={filters.page === item ? 'transparent' : colors.footer}
              style={{ cursor: 'pointer' }}
              onClick={() => changeFilters({ page: item })}
            >
              <Typography color={filters.page === item ? colors.footer : 'white'} fontWeight={600}>
                {item}
              </Typography>
            </Box>
          ))}
          {isShowDots && <Typography color={colors.footer}>...</Typography>}
          {endButtons.map((item) => (
            <Box
              key={item}
              px="10px"
              py="5px"
              borderRadius={0.5}
              bgcolor={filters.page === item ? 'transparent' : colors.footer}
              style={{ cursor: 'pointer' }}
              onClick={() => changeFilters({ page: item })}
            >
              <Typography color={filters.page === item ? colors.footer : 'white'} fontWeight={600}>
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      <Box display="flex" gap="20px" alignItems="center" mr="20px">
        <InputPagination page={filters.page} totalPages={totalPages} changeFilters={changeFilters} />
      </Box>
    </Box>
  );
};

export default ListFooter;
