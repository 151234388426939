import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import colors from '../utils/colors';

const useColors = () => {
  const { pathname } = useLocation();
  const isMirlOfficial = useMemo(() => pathname === '/mirl', [pathname]);

  const optionColors = {
    headerDropdownButton: colors.headerDropdownButton,
    headerDropdownBg: colors.headerDropdownBg,
    headerDropdownText: colors.headerDropdownText,
    headerMenuButton: colors.headerMenuButton,
    bg: isMirlOfficial ? colors.bg : colors.bgBybit,
    cardBg: isMirlOfficial ? colors.cardBg : colors.mainBybit,
    footer: isMirlOfficial ? colors.main : colors.mainBybit,
    id: isMirlOfficial ? colors.main : colors.lightBlack,
    buttonBg: isMirlOfficial ? colors.main : 'black',
    detailModalBg: isMirlOfficial ? colors.bg : colors.cardBg,
    detailRanking: isMirlOfficial ? colors.main : 'white',
    rarityScore: isMirlOfficial ? colors.main : colors.mainBybit,
    attribute: isMirlOfficial ? colors.main : 'white',
    attributeBar: isMirlOfficial ? colors.main : colors.mainBybit,
    attributeText: isMirlOfficial ? 'white' : colors.lightBlack,
    ownerText: isMirlOfficial ? colors.main : colors.lightBlack,
    line: colors.line,
  };

  return optionColors;
};

export default useColors;
