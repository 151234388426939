import logo from './images/logo.png';
import discord from './images/discord.svg';
import telegram from './images/telegram.svg';
import twitter from './images/twitter.svg';
import bybit from './images/ByBit.png';
import iconCaretDown from './images/IconCaretDown.svg';

const Images = {
  logo,
  discord,
  telegram,
  twitter,
  bybit,
  iconCaretDown,
};

export default Images;
