import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const Loading = ({ isLoading }) => {
  const styles = useStyles();

  return (
    <Backdrop className={styles.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1000,
    color: 'white',
  },
}));
