import { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Typography, Checkbox, useMediaQuery, useTheme } from '@mui/material';
import { SearchOutlined, Add, KeyboardArrowUp } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import Button from '../../../components/Button';
import allTraits from '../../../assets/jsons/traits.json';
import useColors from '../../../hooks/useColors';

const MAX_CHARACTERS = 4;
const bybitBackgrounds = ['The Maven', 'The Ally', 'The Maker', 'The Pathfinder', 'The Forager'];

const Sidebar = ({
  isMirlOfficial,
  filters,
  changeFilters,
  toggleAttributeFilters,
  isAllAttributeSelected,
  selectAllAttributeFilter,
  deSelectAllAttributeFilter,
}) => {
  const timeout = useRef();
  const colors = useColors();
  const [search, setSearch] = useState(0);
  const [startRanking, setStartRanking] = useState(0);
  const [endRanking, setEndRanking] = useState(0);
  const [openingAttributes, setOpeningAttributes] = useState([]);

  const traits = allTraits.map((item) => {
    if (item.name !== 'Background') return item;

    const bgs = item.values;
    const values = isMirlOfficial
      ? bgs.filter((bg) => !bybitBackgrounds.includes(bg))
      : bgs.filter((bg) => bybitBackgrounds.includes(bg));

    return { ...item, values };
  });

  useEffect(() => {
    setSearch(0);
    setStartRanking(0);
    setEndRanking(0);
    setOpeningAttributes([]);
  }, [isMirlOfficial]);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(applyFilters, 200);
  }, [search]);

  const toggleAttributes = useCallback(
    (name) => {
      if (openingAttributes.includes(name)) {
        setOpeningAttributes(openingAttributes.filter((item) => item !== name));
        return;
      }

      setOpeningAttributes([...openingAttributes, name]);
    },
    [openingAttributes]
  );

  const isOpenAttributes = useCallback((name) => openingAttributes.includes(name), [openingAttributes]);

  const isChecked = useCallback(
    (name, value) => filters.attributes.some((item) => item.trait_type === name && item.value === value),
    [filters]
  );

  const applyFilters = useCallback(() => {
    changeFilters({ search, startRanking, endRanking, page: 1 });
  }, [search, startRanking, endRanking, changeFilters]);

  const styles = useStyles();
  const theme = useTheme();
  const isOneColumnLayout = useMediaQuery(theme.breakpoints.down('lg'));

  const rankingFilterInvalid =
    (startRanking && !endRanking) || (!startRanking && endRanking) || startRanking > endRanking;

  return (
    <Box
      height={isOneColumnLayout ? 'auto' : 'calc(100vh - 90px)'}
      overflow={isOneColumnLayout ? 'hidden' : 'auto'}
      display="flex"
      flexDirection="column"
      gap={2}
      borderRight={`1px solid ${colors.line}`}
    >
      <Box p="10px" pb="0px" display="flex" flexDirection="column" gap="10px">
        <Typography color="white" fontSize="17.5px" fontWeight={600}>
          Filters
        </Typography>
        <Box
          px="20px"
          py="10px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          color="gray"
          bgcolor="white"
        >
          <input
            className={styles.input}
            type="number"
            value={search || ''}
            onChange={(e) => {
              if (e.target.value && e.target.value.length > MAX_CHARACTERS) return;
              setSearch(+(e.target.value || '0'));
            }}
            onKeyUp={(e) => e.key === 'Enter' && applyFilters()}
            placeholder="Search here..."
          />
          <SearchOutlined color="inherit" />
        </Box>
        <Box mt="20px" mb="10px" display="flex" flexDirection="column" gap="10px">
          <Typography color="white" fontSize="17.5px" fontWeight={600}>
            Rarity rank
          </Typography>
          <Box display="flex" gap="20px" alignItems="center">
            <Box px="20px" py="10px" display="flex" alignItems="center" color="gray" bgcolor="white" flex={1}>
              <input
                className={styles.input}
                type="number"
                value={startRanking || ''}
                onChange={(e) => {
                  if (e.target.value && e.target.value.length > MAX_CHARACTERS) return;
                  setStartRanking(+(e.target.value || '0'));
                }}
                placeholder="#Rank"
              />
            </Box>
            <Typography color="white">to</Typography>
            <Box px="20px" py="10px" display="flex" alignItems="center" color="gray" bgcolor="white" flex={1}>
              <input
                className={styles.input}
                type="number"
                value={endRanking || ''}
                onChange={(e) => {
                  if (e.target.value && e.target.value.length > MAX_CHARACTERS) return;
                  setEndRanking(+(e.target.value || '0'));
                }}
                placeholder="#Rank"
              />
            </Box>
          </Box>
          {rankingFilterInvalid && (
            <Box>
              <Typography fontSize="12px" color="tomato">
                End rank must be greater than start rank.
              </Typography>
            </Box>
          )}
        </Box>
        <Button label="Apply filters" isDisabled={rankingFilterInvalid} onClick={applyFilters} />
      </Box>
      <Box pt="30px">
        <Box borderBottom={`1px solid ${colors.line}`}>
          <Box p="10px">
            <Typography color="white" fontSize="15.5px" fontWeight={600}>
              Traits
            </Typography>
          </Box>
        </Box>
        {traits.map((item) => (
          <Box key={item.name} borderBottom={`1px solid ${colors.line}`}>
            <Box
              py="20px"
              px="10px"
              height="20px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              color="white"
            >
              <Typography color="white" fontSize="14px" fontWeight={600}>
                {item.name}
              </Typography>
              {isOpenAttributes(item.name) ? (
                <KeyboardArrowUp
                  color="inherit"
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleAttributes(item.name)}
                />
              ) : (
                <Add color="inherit" style={{ cursor: 'pointer' }} onClick={() => toggleAttributes(item.name)} />
              )}
            </Box>
            {isOpenAttributes(item.name) && (
              <Box p="10px">
                <Box
                  key={item.value}
                  display="flex"
                  gap="10px"
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    isAllAttributeSelected({ name: item.name, values: item.values })
                      ? deSelectAllAttributeFilter(item.name)
                      : selectAllAttributeFilter({ name: item.name, values: item.values })
                  }
                >
                  <Checkbox
                    sx={{
                      color: 'white',
                    }}
                    checked={isAllAttributeSelected({ name: item.name, values: item.values })}
                    onChange={() =>
                      isAllAttributeSelected({ name: item.name, values: item.values })
                        ? deSelectAllAttributeFilter(item.name)
                        : selectAllAttributeFilter({ name: item.name, values: item.values })
                    }
                  />
                  <Typography color="white" fontSize="14px" sx={{ mt: 1 }}>
                    {isAllAttributeSelected({ name: item.name, values: item.values }) ? 'De-select all' : 'Select all'}
                  </Typography>
                </Box>
                {item.values.map((value) => (
                  <Box
                    key={value}
                    display="flex"
                    gap="10px"
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleAttributeFilters({ trait_type: item.name, value })}
                  >
                    <Checkbox
                      sx={{
                        color: 'white',
                      }}
                      checked={isChecked(item.name, value)}
                      onChange={() => toggleAttributeFilters({ trait_type: item.name, value })}
                    />
                    <Typography color="white" fontSize="14px" sx={{ mt: 1 }}>
                      {value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Sidebar;

const useStyles = makeStyles(() => ({
  input: {
    flex: 1,
    border: 'none',
    outline: 'none',
    width: '100%',
    fontSize: '13px',
    fontWeight: 600,
  },
}));
