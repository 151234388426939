import { Box, Grid } from '@mui/material';

import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import DetailModal from '../../components/DetailModal';
import Loading from '../../components/Loading';

import useQuery from '../../hooks/useQuery';

const Home = () => {
  const {
    isLoading,
    isMirlOfficial,
    items,
    totalPages,
    filters,
    openingItem,
    setOpeningItem,
    changeFilters,
    toggleAttributeFilters,
    isAllAttributeSelected,
    selectAllAttributeFilter,
    deSelectAllAttributeFilter,
  } = useQuery();

  return (
    <Box>
      <Loading isLoading={isLoading} />
      <DetailModal item={openingItem} close={() => setOpeningItem(null)} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
          <Sidebar
            isMirlOfficial={isMirlOfficial}
            filters={filters}
            changeFilters={changeFilters}
            toggleAttributeFilters={toggleAttributeFilters}
            isAllAttributeSelected={isAllAttributeSelected}
            selectAllAttributeFilter={selectAllAttributeFilter}
            deSelectAllAttributeFilter={deSelectAllAttributeFilter}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
          <MainContent
            isMirlOfficial={isMirlOfficial}
            items={items}
            filters={filters}
            totalPages={totalPages}
            setOpeningItem={setOpeningItem}
            changeFilters={changeFilters}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
