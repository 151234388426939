import { Box, Typography } from '@mui/material';

import useColors from '../hooks/useColors';

const Button = ({ label, fontSize, isDisabled = false, onClick }) => {
  const colors = useColors();

  return (
    <Box
      px="20px"
      py="10px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={colors.buttonBg}
      borderRadius={1}
      onClick={() => !isDisabled && onClick()}
      style={{ cursor: isDisabled ? 'default' : 'pointer', opacity: isDisabled ? 0.6 : 1 }}
    >
      <Typography color="white" fontWeight={600} fontSize={fontSize || '15px'} align="center">
        {label}
      </Typography>
    </Box>
  );
};

export default Button;
