import { Box, Grid, Paper, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ListFooter from './ListFooter';
import Button from '../../../components/Button';
import useColors from '../../../hooks/useColors';
import { renderAddress } from '../../../utils/helpers';

const MainContent = ({ isMirlOfficial, items, filters, totalPages, setOpeningItem, changeFilters }) => {
  const colors = useColors();
  const styles = useStyles();
  const theme = useTheme();
  const isOneColumnLayout = useMediaQuery(theme.breakpoints.down('lg'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const viewOnOpensea = (edition) =>
    window.open(`https://opensea.io/assets/ethereum/0xfda07191be8d7019789cd1e1cf86d97604ce7351/${edition}`);

  const viewAddressOnOpeasea = (address) => address && window.open(`https://opensea.io/${address}`);

  return (
    <Box
      p="10px"
      height={isOneColumnLayout ? 'auto' : 'calc(100vh - 90px)'}
      overflow={isOneColumnLayout ? 'hidden' : 'auto'}
    >
      <Box>
        <Typography fontSize="26.5px" color="white" fontWeight={600}>
          {isMirlOfficial ? 'MIRL OFFICIAL' : 'MIRL x BYBIT'} RARITY RANKING
        </Typography>
      </Box>
      <Box my="10px">
        <Grid container spacing="10px">
          {items.map((item) => (
            <Grid key={item.edition} item xs={6} sm={6} md={3} lg={3} xl={3}>
              <Paper elevation={3} className={styles.item} style={{ backgroundColor: colors.cardBg }}>
                <Stack p="20px" spacing="20px">
                  <Typography fontWeight={600} fontSize="21px" color="white">
                    #{item.ranking}
                  </Typography>
                  <Box borderRadius={1} overflow="hidden">
                    <img
                      src={item.image}
                      alt={item.name}
                      width="100%"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setOpeningItem(item)}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Typography align="center" fontWeight={600} fontSize="18px" color={colors.id}>
                      {item.name.replace('#', '')}
                    </Typography>
                    {isMirlOfficial && item.owner && item.owner.address && (
                      <Typography align="center" fontSize="18px" color={colors.ownerText} className={styles.ownerText}>
                        Owner:{isMd ? <br /> : ' '}
                        <span className={styles.address} onClick={() => viewAddressOnOpeasea(item.owner.address)}>
                          {item.owner.name || renderAddress(item.owner.address)}
                        </span>
                      </Typography>
                    )}
                  </Box>
                  {isMirlOfficial && (
                    <Button label="View on Opensea" fontSize="18px" onClick={() => viewOnOpensea(item.edition)} />
                  )}
                </Stack>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      <ListFooter totalPages={totalPages} filters={filters} changeFilters={changeFilters} />
    </Box>
  );
};

export default MainContent;

const useStyles = makeStyles(() => ({
  item: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
  address: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  ownerText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));
