import { Box, Typography } from '@mui/material';

import useColors from '../../../hooks/useColors';

const InputPagination = ({ page, totalPages, changeFilters }) => {
  const colors = useColors();

  return (
    <Box display="flex" gap="10px" alignItems="center">
      {page > 1 && (
        <Typography
          color={colors.footer}
          fontWeight={600}
          style={{ cursor: 'pointer' }}
          onClick={() => changeFilters({ page: page - 1 })}
        >
          Prev
        </Typography>
      )}
      <Box mx={2} display="flex" gap="10px" alignItems="center">
        <input
          type="number"
          value={page || ''}
          onChange={(e) => changeFilters({ page: parseInt(e.target.value || '0') })}
          style={{ height: 30, width: 50 }}
        />
        <Typography color={colors.footer}>of {totalPages} pages</Typography>
      </Box>
      {page < totalPages && (
        <Typography
          color={colors.footer}
          fontWeight={600}
          style={{ cursor: 'pointer' }}
          onClick={() => changeFilters({ page: page + 1 })}
        >
          Next
        </Typography>
      )}
    </Box>
  );
};

export default InputPagination;
