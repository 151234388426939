import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme, useMediaQuery, Stack } from '@mui/material';
import { Menu } from '@mui/icons-material';

import Images from '../assets/Images';
import useColors from '../hooks/useColors';

const links = [
  { text: 'ABOUT', url: 'https://mirl.club/#About' },
  { text: 'ROADMAP', url: 'https://mirl.club/#Roadmap' },
  { text: 'PARTNERS', url: 'https://mirl.club/#Partners' },
  { text: 'SHOP', url: 'https://shop.mirl.club/' },
  { text: 'JOIN DAO', url: 'https://mirl.club/join' },
  {
    text: 'RARITY RANKING',
    isDropDown: true,
    children: [
      { text: 'MIRL OFFICIAL', url: '/mirl', isSameOrigin: true },
      { text: 'MIRL x BYBIT', url: '/mirlxbybit', isSameOrigin: true },
    ],
  },
  {
    text: 'MORE',
    isDropDown: true,
    children: [
      { text: 'MEDIA', url: 'https://mirl.club/#Media' },
      { text: 'TEAM', url: 'https://mirl.club/#Team' },
      { text: 'VOTING', url: 'https://vote.mirl.club/' },
      { text: 'CLAIM AIRDROP', url: 'https://airdrop.mirl.club/' },
      { text: 'MIRL STAKING', url: 'https://staking.mirl.club/' },
    ],
  },
];

const socials = [
  { icon: Images.discord, url: 'https://discord.com/invite/qSwgPSj2RT' },
  { icon: Images.twitter, url: 'https://twitter.com/mirlnft' },
  { icon: Images.telegram, url: 'https://t.me/MIRL_Official' },
];

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMirlOfficial = pathname === '/mirl';
  const [isOpenDropdown, setIsOpenDropdown] = useState(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const colors = useColors();

  const theme = useTheme();
  const isOneColumnLayout = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      height="90px"
      px={isOneColumnLayout ? '20px' : '80px'}
      display="flex"
      alignItems="center"
      bgcolor="black"
      position="relative"
    >
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        style={{ cursor: 'pointer' }}
        onClick={() => (window.location.href = 'https://mirl.club/')}
      >
        <img src={Images.logo} alt="logo" width={isSmall ? 100 : 140} />
        {!isMirlOfficial && (
          <>
            <Box mx={2}>
              <Typography color="white" fontSize="16px" fontWeight={600}>
                X
              </Typography>
            </Box>
            <img src={Images.bybit} alt="logo" height={isSmall ? 48 : 68} />
          </>
        )}
      </Box>
      {!isOneColumnLayout ? (
        <Box display="flex" gap="40px" alignItems="center">
          {links.map((item) => (
            item.isDropDown ? (
              <Box
                key={item.text}
                p="10px"
                position="relative"
                display="flex"
                alignItems="center"
                bgcolor={isOpenDropdown === item.text ? colors.headerDropdownButton : 'transparent'}
                borderRadius={1}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (isOpenDropdown !== item.text) setIsOpenDropdown(item.text)
                  else setIsOpenDropdown(null)
                }}
              >
                <Typography
                  color="white"
                  fontSize="17px"
                  fontWeight={600}
                  fontFamily="DarkerGrotesque !important"
                  style={{ cursor: 'pointer' }}
                >
                  {item.text}
                </Typography>
                <img
                  src={Images.iconCaretDown}
                  width={27}
                  height={18}
                  alt="Arrow down"
                />
                {isOpenDropdown === item.text &&
                  <Box position="absolute" top="100%" left={0} borderRadius={1} overflow="hidden">
                    {item.children.map((children) => (
                      <Box
                        key={children.url}
                        px={4}
                        py={1.5}
                        bgcolor={colors.headerDropdownBg}
                        sx={{
                          transition: 'all .3s ease',
                          "&:hover": { backgroundColor: '#8982bd' }
                        }}
                        onClick={() => {
                          if (children.isSameOrigin) navigate(children.url);
                          else window.open(children.url);
                          setIsOpenDropdown(null);
                        }}
                      >
                        <Typography
                          align="center"
                          fontWeight={600}
                          fontSize="14px"
                          fontFamily="DarkerGrotesque !important"
                          color="white"
                          sx={{
                            textDecoration: (children.isSameOrigin && pathname === children.url)
                              ? 'underline' : 'none',
                            textUnderlineOffset: 3,
                          }}
                        >
                          {children.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                }
              </Box>
            ) : (
              <Typography
                key={item.text}
                color="#fff"
                fontSize="17px"
                fontWeight={600}
                fontFamily="DarkerGrotesque !important"
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(item.url)}
              >
                {item.text}
              </Typography>
            )
          ))}
          <Box width={4} height={4} borderRadius="50%" bgcolor="white" />
          <Box display="flex" alignItems="center" gap="30px">
            {socials.map((social) => (
              <img
                key={social.icon}
                alt="social-icon"
                src={social.icon}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(social.url)}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          width="68px"
          height="68px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="6px"
          color="white"
          bgcolor={isOpenMenu ? colors.headerMenuButton : 'transparent'}
        >
          <Menu color="inherit" style={{ cursor: 'pointer' }} onClick={() => setIsOpenMenu(!isOpenMenu)} />
        </Box>
      )
      }
      {
        isOneColumnLayout && isOpenMenu && (
          <Box py="40px" position="absolute" top="100%" left={0} width="100%" bgcolor="black" zIndex={99}>
            <Stack spacing="40px">
              {links.map((item) => (
                item.isDropDown ? item.children.map(children => (
                  <Box key={children.text}>
                    <Typography
                      align="center"
                      fontSize="20px"
                      fontWeight={600}
                      fontFamily="TS-Block !important"
                      color="white"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (children.isSameOrigin) navigate(children.url);
                        else window.open(children.url);
                        setIsOpenMenu(false);
                      }}
                    >
                      {children.text}
                    </Typography>
                  </Box>
                ))
                  : (
                    <Box key={item.text}>
                      <Typography
                        align="center"
                        fontSize="20px"
                        fontWeight={600}
                        fontFamily="TS-Block !important"
                        color="white"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          window.open(item.url);
                          setIsOpenMenu(false);
                        }}
                      >
                        {item.text}
                      </Typography>
                    </Box>)
              ))}
            </Stack>
          </Box >
        )
      }
    </Box >
  );
};

export default Header;
