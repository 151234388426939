const colors = {
  headerDropdownButton: '#3b2574',
  headerDropdownBg: '#000',
  headerDropdownText: '#807e80',
  headerMenuButton: '#ada0f0',
  bg: '#0e0329',
  bgBybit: '#1a2039',
  line: '#362b5c',
  main: '#ada0f0',
  mainBybit: '#f7a600',
  cardBg: '#1b1529',
  lightBlack: '#1a2039',
};

export default colors;
